module controllers {
    export module payment {
        interface IPaymentCreateScope extends ng.IScope {
        }

        interface IPaymentCreateParams extends ng.ui.IStateParamsService {
            payId: number;  
            ownerEntityId : number;
        }

        export class paymentCreateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'paymentService',
                "customerService",
                "paymentMethodService",
                "counterService",
                'currencyService',
                "isInbound"
            ];

            IsInbound: boolean = false;

            //the below variables must be passed in if this is an existing invoice
            payId: number;            
            customerId: number = 0;
            supplierId: number = 0;
            currencyId: number = 0;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            disablePaymentTab: boolean = false;
            disableInvoiceTab: boolean = false;

            selectedTab: number = 0;

            paymentTabHeading: string = "";

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            IsDisabled: boolean = false;


            ownerEntityId: number = 0;
            entityList: Array<interfaces.applicationcore.IEntity>;

             //Supplier Variables
             supplierModel: interfaces.applicationcore.IDropdownModel;
             supplierList: Array<interfaces.applicationcore.IDropdownModel>;
 
             //Customer Variables
             customerModel: interfaces.applicationcore.IDropdownModel;
             customerList: Array<interfaces.applicationcore.IDropdownModel>;

             currencyList: Array<interfaces.applicationcore.IDropdownModel>;
             currencyModel : interfaces.applicationcore.IDropdownModel;

             paymentMethodModel : interfaces.applicationcore.IDropdownModel;

             isAdvancedPayment : boolean = false;
             advancePaymentAmount : number = 0;

             customPaymentNumberCheck: Boolean = false;

             paymentCounterId : number = 0;
             paymentCounterList: Array<interfaces.applicationcore.IDropdownModel>;

            loadPromises: ng.IPromise<any>[];

            isCustomPaymentNumber: boolean = false;
            paymentCounterNumber: string = "";

            NextStepButton: string = "Next";

            disableInvoices: boolean = true;
            ShowInvoicesGrid: boolean = false;
            DisableNextButton: boolean = false;
            IsLoading: boolean = false;

            IsValid: boolean = false;

            apiInvList: uiGrid.IGridApi;
            selectedInv: uiGrid.IGridRow;

            invoiceIdList: interfaces.payment.IPaymentInvoice[] = [];
      
            constructor(
                private $scope: IPaymentCreateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IPaymentCreateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private paymentService: interfaces.payment.IPaymentService,
                private customerService: interfaces.master.ICustomerService,
                public paymentMethodService: services.master.paymentMethodService,
                private counterService: interfaces.master.ICounterService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private isInbound: boolean,
            ) {

                this.payId = $stateParams.payId;
                this.ownerEntityId = $stateParams.ownerEntityId;
                this.supplierId = $stateParams.supplierId;
                this.customerId = $stateParams.customerId;
                this.currencyId = $stateParams.currencyId;

                this.IsInbound = isInbound;

                if (this.isInbound){
                    if (this.payId === 0){
                        this.breadCrumbDesc = "Create Supplier Payment";
                    }
                    else {
                        this.breadCrumbDesc = "Update Supplier Payment Invoices";
                    }

                    this.paymentTabHeading = "Supplier Payment"
                }
                else
                {
                    if (this.payId === 0 ){
                        this.breadCrumbDesc = "Create Customer Payment";
                    }
                    else
                    {
                        this.breadCrumbDesc = "Update Customer Payment Invoices";
                    }
                    this.paymentTabHeading = "Customer Payment"
                }

                if (this.payId > 0)
                {
                    this.disableInvoices = false;
                    this.ShowInvoicesGrid = true;
                    this.disablePaymentTab = true;
                    this.DisableNextButton = false;
                    this.NextStepButton = "Submit";
                    this.LoadInvoices();
                    this.selectedTab = 1;
                }
                else
                {

                   //Get Entities Data
                   this.loadPromises = [];

                    //We need to load the Entities and the Current Entity First
                    //Current Entity can be either the active Entity the user is working on  --> New Payment
                    //OR Current Entity will be the OwnerEntity of the Payment --> Existing Payment

                    this.loadPromises.push(this.loadEntities());
                    this.loadPromises.push(this.getCurrentEntity());


                    bsLoadingOverlayService.wrap({
                        referenceId: 'create.payment'
                    }, () => {
                        return $q.all(this.loadPromises).then((data) => {
                            //Now we can load the rest of the Controls, after determining which is the CurrentEntity
    
                            this.loadControls();
                        });
                    });

                }
            }

            isFormValid() : Boolean {
                this.IsValid = false;


                return this.IsValid;
            }


            loadControls() {

                this.loadPromises = [];


                this.loadPromises.push(this.loadPaymentCounter());


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.payment'
                }, () => {
                    return this.$q.all(this.loadPromises).then((data) => {
                        //Now we can load the defaults AFTER all the dropdowns have been loaded


                    });
                    });

            }


            //Loading Entities
            loadEntities() {

                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                if (this.payId === 0) {
                    //This is a New Payment creating
                    //default the CurrentEntity and all other controls from there

                    this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                        this.ownerEntityId = result.Id;
                        
                        deferred.resolve(true);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    });
                }
                /*else {
                    //This is an existing Consignment, so the CurrentEntity must be set 
                    //to the OwnerEntity of the Consignment
                    if (this.consignmentId > 0) {
                        this.consignmentService.GetConsignmentSummary().get({
                            conId: this.consignmentId, comId: this.commercialInvoiceId
                        }, (result: interfaces.consignment.IConsignmentSummary) => {

                            this.ownerEntityId = result.OwnerEntityCode.Id;

                            deferred.resolve(true);

                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            deferred.resolve(true);
                        })
                    }
                    else {
                        deferred.resolve(true);
                    }
                }*/



                return deferred.promise;
            }

             //Load Suppliers
             loadSuppliers(searchText: string)
             {
                 if (this.ownerEntityId) {
                     return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query(() => {
 
                     }, (failureData) => {
                         this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                     }).$promise;
                 } else {
                     var defer = this.$q.defer();
                     defer.resolve([]);
                     return defer.promise;
                 }
             }
 
             //Load Customers
             loadCustomers(searchText: string) {
                 if (this.ownerEntityId) {
                     return this.customerService.getDropdownList(this.ownerEntityId, searchText).query(() => {
 
                     }, (failureData) => {
                         this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                     }).$promise;
                 } else {
                     var defer = this.$q.defer();
                     defer.resolve([]);
                     return defer.promise;
                 }
             }

             supplierChanged(supplierModel: interfaces.applicationcore.IDropdownModel)
             {
                this.supplierModel = supplierModel;
                this.currencyModel = null;


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.payment'
                }, () => {
                     return this.loadSupplierCurrencyList().then((data) => {
                        this.getCreatePaymentDefaults();
                    });
                });
             }

             customerChanged(customerModel: interfaces.applicationcore.IDropdownModel)
             {
                this.customerModel = customerModel;
                this.currencyModel = null;


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.payment'
                }, () => {
                     return this.loadCustomerCurrencyList().then((data) => {
                        this.getCreatePaymentDefaults();
                    });
                });
             }

             loadCustomerCurrencyList(){
                return this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    this.currencyList = currencyList;
    
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;      
             }



            loadSupplierCurrencyList() {
                return this.paymentService.getCurrencyDropdownForSupplier(this.supplierModel.Id).query({
                }, (resultList) => {
                        this.currencyList = resultList;
                }).$promise;
            }

            loadPaymentMethods() {
                return this.paymentMethodService.getDropdownList(this.ownerEntityId).query({
                }, (resultList) => {
                }).$promise;
            }

            isAdvancedPaymentChanged(){
                if (!this.isAdvancedPayment)
                {
                    this.advancePaymentAmount = 0;
                }
            }

            //Loading Consignment Counters
            loadPaymentCounter() {

                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {

                    this.counterService.getDropdownList().query({
                        ownerEntityId: this.ownerEntityId,
                        counterTypeId: this.isInbound? Enum.EnumCounterTypes.SupplierPayment : Enum.EnumCounterTypes.CustomerPayment,
                        isInbound: this.isInbound
                    }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                        this.paymentCounterList = result;

                        angular.forEach(result, (o) => {
                            if (o.Selected) {
                                this.paymentCounterId = o.Id;
                            }
                        });

                        deferred.resolve(true);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    })
                } else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            customPaymentCounterChanged(){
                this.paymentCounterNumber = '';
                if (this.isCustomPaymentNumber)
                {
                    this.paymentCounterId = 0;                    
                }
                else
                {

                }
            }

            getCreatePaymentDefaults(){

                var supplierId = this.supplierModel ? this.supplierModel.Id : 0;
                var customerId = this.customerModel ? this.customerModel.Id : 0;

                return this.paymentService.GetCreatePaymentDefaults(this.ownerEntityId,this.isInbound,supplierId,customerId).get((data: interfaces.payment.IPaymentCreateDefaults) => {

                    this.paymentCounterId = data.paymentCounterId ? data.paymentCounterId : undefined;
                    this.currencyModel = data.currencyModel ? data.currencyModel : undefined;
                    this.paymentMethodModel = data.paymentMethodModel ? data.paymentMethodModel : undefined;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise
            }

            NextStep(){
                this.selectedTab++;
                
                if (this.selectedTab == 1) {

                    this.disableInvoices = false;
                    this.ShowInvoicesGrid = true;
                    this.DisableNextButton = true;
                    this.NextStepButton = "Submit";
                    this.LoadInvoices();

                } else if (this.selectedTab == 2 && this.ShowInvoicesGrid) {
                    this.SubmitPayment();
                } 
            }

            SubmitPayment(){

                this.disableInvoices = true;

                this.IsLoading = true;

               
                var invoiceIdList: interfaces.payment.IPaymentInvoice[] = [];

                var paymentInvoiceLines : interfaces.payment.IPaymentInvoiceLines = {
                    invoiceIdList: []
                };

                if (this.selectedInv && this.apiInvList.selection.getSelectedRows().length > 0) {

                    this.invoiceIdList = [];

                    this.invoiceIdList = _.map(this.apiInvList.selection.getSelectedRows(), (o) => {
                        return {
                            invoiceId : o.comId,
                            payId : 0,
                            AmountPaid: o.AmountOwing
                        };
                    });
                }

                paymentInvoiceLines.invoiceIdList =  this.invoiceIdList;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.payment'
                }, () => {
                    if (this.payId === 0 ){
                        return this.CreateFromInvoice(paymentInvoiceLines);
                    }
                    else
                    {
                    return this.UpdatePaymentfromInvoiceList(paymentInvoiceLines);
                    }
                });
            }

            UpdatePaymentfromInvoiceList( paymentInvoiceLines : interfaces.payment.IPaymentInvoiceLines)
            {
                var supplierId = this.supplierModel ? this.supplierModel.Id : 0;
                var customerId = this.customerModel ? this.customerModel.Id : 0;

                return this.paymentService.updatePaymentFromInvoiceLines(
                    this.payId               
                ).save(paymentInvoiceLines, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {
                        if (this.isInbound)
                            this.$state.go("auth.SupplierPayments.Update", { payId: Number(result.ID) });
                        else
                            this.$state.go("auth.CustomerPayments.Update", { payId: Number(result.ID) });
                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);

                    this.IsLoading = false;

                }).$promise;
            }

            CreateFromInvoice( paymentInvoiceLines : interfaces.payment.IPaymentInvoiceLines){

                var supplierId = this.supplierModel ? this.supplierModel.Id : 0;
                var customerId = this.customerModel ? this.customerModel.Id : 0;

                return this.paymentService.createPaymentFromInvoiceLines(

                    this.ownerEntityId,
                    supplierId,
                    customerId,
                    this.currencyModel.Id,
                    this.isInbound,
                    this.paymentMethodModel.Id,
                    this.isAdvancedPayment,
                    this.advancePaymentAmount ? this.advancePaymentAmount : 0,
                    this.paymentCounterId ? this.paymentCounterId : 0,
                    this.paymentCounterNumber ? this.paymentCounterNumber : ""                    

                ).save(paymentInvoiceLines, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {
                        if (this.isInbound)
                            this.$state.go("auth.SupplierPayments.Update", { payId: Number(result.ID) });
                        else
                            this.$state.go("auth.CustomerPayments.Update", { payId: Number(result.ID) });
                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);

                    this.IsLoading = false;

                }).$promise;
            }

            LoadInvoices(){

                this.ShowInvoicesGrid = false;
                this.IsLoading = true;

                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create.payment'
                    }, () => {

                        if (this.payId === 0)
                        {
                            var supplierId = this.supplierModel? this.supplierModel.Id : 0;
                            var customerId = this.customerModel? this.customerModel.Id : 0;
                            var currencyId = this.currencyModel? this.currencyModel.Id : 0;
                        }
                        else
                        {
                            var supplierId = this.supplierId;
                            var customerId = this.customerId;
                            var currencyId = this.currencyId;
                        }

                        return this.paymentService.getInvoicesForPayment(
                            this.ownerEntityId,            
                            this.payId,                
                            supplierId,
                            customerId,
                            currencyId,
                            this.isInbound
                        ).query((data) => {

                            this.gvwInvoices.data = data;
                            this.IsLoading = false;

                            for (let item of this.gvwInvoices.data) {
                                if ((item as interfaces.payment.IPaymentInvoiceLinesDisplay).Selected) {
                                    this.selectedInv = item;
                                    this.$timeout(() => {
                                        this.apiInvList.selection.unSelectRow(item);
                                        this.apiInvList.selection.selectRow(item);
                                    });
                                }
                            }

                            this.DisableNextButton = false;
                            this.ShowInvoicesGrid = true;

                            if (this.apiInvList.selection.getSelectedRows().length === 0 && !this.isAdvancedPayment) {
                                if (this.payId === 0){ 
                                    //only disable if this is a NEW payment and not advanced payment
                                    //the user must selected an invoice in this case
                                    //if its an existing invoice the user can remove all invoices linked to payment so they can cancel the payment.                                
                                    this.DisableNextButton = true;
                                }
                            }
                            else
                            {
                                this.DisableNextButton = false;
                            }

                            /*if (this.consignmentTypeDefaults.EnforceLinkingCItoSO && this.apiSOList.selection.getSelectedRows().length === 0) {
                                this.DisableNextButton = true;
                            }

                            if (!this.consignmentTypeDefaults.EnforceLinkingCItoSO && this.apiSOList.selection.getSelectedRows().length === 0) {
                                this.DisableNextButton = false;
                                this.NextStepButton = "Submit";
                            }*/


                        },
                            (errorResponse) => {

                                this.generalService.displayMessageHandler(errorResponse.data);
                                this.IsLoading = false;
                            }).$promise;
                    });
                });
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                
                this.apiInvList = gridApi;
                this.apiInvList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selectedInv = selected;

                    this.DisableNextButton = false;

                    if (this.apiInvList.selection.getSelectedRows().length === 0 && !this.isAdvancedPayment) {
                        if (this.payId ===  0){
                            //only disable this button if its a NEW Payment and Not Advanced Payment
                            //existing payments can have all invoices unlinked, so it can be cancelled
                            this.DisableNextButton = true;
                        }
                    }
                    else
                    {
                        this.DisableNextButton = false;
                    }
                });

                this.$timeout(() => {
                    if (this.selectedInv)
                        this.apiInvList.selection.selectRow(this.selectedInv.entity);
                });
            }

             //Build UIGrid
             gvwInvoices: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ui-sref="auth.ConsignmentExports.Update({ conId: row.entity.Id })" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: false
                },
                {
                    name: "InvoiceNumber",
                    displayName: "Invoice Number",
                    field: "InvoiceNumber",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    
                },
                {
                    name: "ConsignmentNumber",
                    displayName: "Consignment",
                    field: "ConsignmentNumber",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    
                },
                {
                    name: "TermsOfPaymentCode",
                    displayName: "Terms of Payment",
                    field: "TermsOfPaymentCode",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "TotalInvoiceValue",
                    displayName: "Value",
                    field: "TotalInvoiceValue",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },{
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "IsPaymentDue",
                    displayName: "Is Due",
                    enableFiltering: true,
                    enableSorting: true,
                    width: 100,
                    cellTemplate: `<input type="checkbox"  ng-disabled="true" ng-model="row.entity.IsPaymentDue">` ,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return searchTerm == cellValue;
                        }
                    },                   
                },
                {
                    name: "PaymentDueDate",
                    displayName: "Payment Due Date",
                    field: "PaymentDueDate",
                    width: 200,
                    cellFilter: "date:'yyyy/MM/dd'",
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="dateTo"></gts-date>
                                
                    </div>`,
                    filter: {
                        condition: function (searchTerm, cellValue) {
                            if (cellValue){
                                var d1= new Date(searchTerm);
                                var d2= new Date(cellValue);

                                if (d1.toDateString() === d2.toDateString()) {
                                    return true;
                                } else {
                                    return false
                                }
                            }
                            else return false;
                        }
                    },
                },
                {
                    name: "Discount Date",
                    displayName: "Discount Date",
                    field: "DiscountDate",
                    width: 200,
                    visible: this.isInbound,
                    cellFilter: "date:'yyyy/MM/dd'",
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="dateTo"></gts-date>
                                
                    </div>`,
                    filter: {
                        condition: function (searchTerm, cellValue) {
                            if (cellValue){
                                var d1= new Date(searchTerm);
                                var d2= new Date(cellValue);

                                if (d1.toDateString() === d2.toDateString()) {
                                    return true;
                                } else {
                                    return false
                                }
                            }
                            else return false;
                        }
                    },
                },
                {
                    name: "AmountOwing",
                    displayName: "Amount Owing",
                    field: "AmountOwing",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }]
            };

            paymentTabSelected(){
            if (this.selectedTab == 0) {
                                this.NextStepButton = "Next";
                            }
                            else
                            {
                                this.NextStepButton = "Submit";
                            }
            }

            invoiceTabSelected(){
                if (this.selectedTab == 0) {
                    this.NextStepButton = "Next";
                }
                else
                {
                    this.NextStepButton = "Submit";
                }
            }

            ownerEntityChanged(){

            }


            close() {
                if (this.payId != 0) {
                    if (this.isInbound)
                        this.$state.go("auth.SupplierPayments.Update", { payId: Number(this.payId) });
                    else
                        this.$state.go("auth.CustomerPayments.Update", { payId: Number(this.payId) });
                }
                else  this.$state.go("^");
            }

          
        };

        angular.module("app").controller("paymentCreateCtrl", controllers.payment.paymentCreateCtrl);
    }
}
